import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import Layout from 'components/Layout';
import Event from 'components/modules/Event';
import BlockRichText from 'components/modules/BlockRichText';
import BlockTextText from 'components/modules/BlockTextText';
import BlockImageText from 'components/modules/BlockImageText';
import BlockLinksList from 'components/modules/BlockLinksList';
// import SEO from '../components/site/SEO';

const Community = ({ data }) => {
  //get the last item in the nodes - this is in case they have a draft version
  //of a new event page
  const communityPage = data.allContentfulCommunity.nodes.slice(-1)[0];
  const { events, textBlocks } = communityPage;

  // State for sorted events
  const [eventsSorted, setEventsSorted] = useState([]);

  // State for number of events to show
  const [eventsIndex, setEventsIndex] = useState(5);
  const eventsIndexIncrementer = 5;

  // Set the events in order by date
  useEffect(() => {
    setEventsSorted(events.sort((a, b) => new Date(b.date) - new Date(a.date)));
  }, [setEventsSorted, events]);

  return (
    <Layout pageTitle="Community">
      <article className="container container--thin container--boxed">
        {/* An events block, a list of events */}
        <section
          className={classnames('block pv3 pv4-xl pv5-xxl', {
            bb: textBlocks.length > 0
          })}
        >
          <ul className="list ph3 ph4-xl">
            {eventsSorted.slice(0, eventsIndex).map((event, index) => {
              return (
                <Event
                  key={index}
                  data={event}
                  className={classnames({ bt: index !== 0 })}
                />
              );
            })}
          </ul>
          {eventsSorted.length > eventsIndex && (
            <section className="tc">
              <button
                className="gradient-hover"
                onClick={() => {
                  setEventsIndex(eventsIndex + eventsIndexIncrementer);
                }}
              >
                Show more events...
              </button>
            </section>
          )}
        </section>
        {/* a block with text left and image right */}
        {textBlocks.map((block, index) => {
          if (block.__typename === 'ContentfulBlockRichText') {
            return (
              <BlockRichText
                key={index}
                data={block}
                className={classnames({
                  bb: index < textBlocks.length - 1
                })}
              />
            );
          } else if (block.__typename === 'ContentfulBlockTextText') {
            return (
              <BlockTextText
                key={index}
                data={block}
                className={classnames({
                  bb: index < textBlocks.length - 1
                })}
              />
            );
          } else {
            return (
              <BlockImageText
                key={index}
                data={block}
                className={classnames({
                  bb: index < textBlocks.length - 1
                })}
              />
            );
          }
        })}
        {/* Links list block */}
        <BlockLinksList isNavList={true} className="bt" />
      </article>
    </Layout>
  );
};

export default Community;

export const query = graphql`
  query communityPage {
    allContentfulCommunity {
      nodes {
        events {
          ... on Node {
            ... on ContentfulBlockEvent {
              title
              date
              description {
                json
              }
              image {
                description
                fluid(maxWidth: 600) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
        textBlocks {
          __typename
          ... on Node {
            ... on ContentfulBlockRichText {
              title
              description {
                json
              }
              twoColumn
            }
            ... on ContentfulBlockTextText {
              title
              textLeft {
                json
              }
              textRight {
                json
              }
            }
            ... on ContentfulBlockImageText {
              title
              image {
                description
                fluid(maxWidth: 600) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              setTextToLeft
              text {
                json
              }
            }
          }
        }
      }
    }
  }
`;
