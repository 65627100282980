import React from 'react';
import { formatDate, richText } from 'components/utils/helpers';
import Image from 'components/base/Image';

const Event = ({ data, className = '' }) => {
  // Accordion toggler
  const toggleAccordion = e => {
    let accordion = e.currentTarget.parentElement;
    accordion.classList.toggle('Accordion--collapse');
  };

  return (
    <li className={`Event Accordion Accordion--collapse pv2 ${className}`}>
      <button
        className="Event__head Accordion__head db w-100 flex justify-between body-subheader gradient-hover pointer"
        onClick={toggleAccordion}
      >
        <div className="Event__name">{data.title}</div>
        {data.date && (
          <div className="Event__date">{formatDate(new Date(data.date))}</div>
        )}
      </button>
      <div className="Event__body Accordion__body mv4 mv2-md">
        <div className="flex flex-column flex-row-md">
          <div className="Event__description w-100 w-50-md body-copy pr0 pr3-md pr4-xl pr5-xxl">
            {richText(data.description.json)}
          </div>
          {data.image && (
            <figure className="Event__images w-100 w-50-md mt4 mt0-md pl0 pl3-md pl4-xl pl5-xxl">
              <Image image={data.image} />
            </figure>
          )}
        </div>
      </div>
    </li>
  );
};

export default Event;
