import React from 'react';
import { richText } from 'components/utils/helpers';
import classnames from 'classnames';
import Image from 'components/base/Image';

const BlockImageText = ({ data, className = '' }) => {
  return (
    <section className={`block pv3 pv4-xl pv5-xxl ${className}`}>
      <h3 className="body-header mb3 mb4-md mb5-xl ph3 ph4-xl ttu">
        {data.title}
      </h3>
      <div
        className={classnames('flex flex-column flex-row-md', {
          'flex-column-reverse flex-row-reverse-md': data.setTextToLeft
        })}
      >
        <div
          className={classnames('w-100 w-50-md body-copy ph3 ph4-xl ph5-xxl', {
            'mt4 mt0-md': data.setTextToLeft
          })}
        >
          {data.image && (
            <figure>
              <Image image={data.image} />
            </figure>
          )}
        </div>
        <div
          className={classnames('w-100 w-50-md body-copy ph3 ph4-xl ph5-xxl', {
            'mt4 mt0-md': !data.setTextToLeft
          })}
        >
          {richText(data.text.json)}
        </div>
      </div>
    </section>
  );
};

export default BlockImageText;
